<template>
  <v-container>
    <div class="d-flex justify-end mt-3" style="height: 68px">
      <span class="filter-btn" @click="switchToggle">
        {{ toggle ? "Скрыть фильтры" : "Показать фильтры" }}
      </span>
    </div>

    <DataFilter v-if="toggle" />
    <infinity-collection @scrolled="getReports">
      <v-data-table
        :mobile-breakpoint="null"
        fixed-header
        :loading="loading"
        style="width: 100%"
        class="elevation-6"
        item-key="id"
        :sort-by="['assessmentSessionDate']"
        :headers="headers"
        :items="content"
        :items-per-page="content.length"
        hide-default-footer
        @click:row="selectReport($event, false)"
        height="450px"
        :custom-sort="customSort"
      >
        <template v-if="role === 'expert'" v-slot:item.actions="{ item }">
          <div class="d-flex align-center">
            <VideoMenu :item="item" />
            <v-btn
              v-if="isCanEdit(item)"
              icon
              small
              class="mr-2"
              @click.stop="selectReport(item, true)"
            >
              <v-icon color="#767676" size="16px"> mdi-pencil </v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </infinity-collection>

    <preloader v-if="loading" class="preloader" />
  </v-container>
</template>

<script>
import { computed, inject } from "@vue/composition-api";

import { useSetFilters, useToggle } from "@/use/helpers";
import useTableHeaders from "@/use/tableHeaders";
import { customSort } from "@/use/tableHeaders";

import DataFilter from "@/components/shared/assets/table/DataFilter";
import VideoMenu from "./report-list/VideoMenu";

export default {
  setup(_, { root }) {
    const store = root.$store;

    const { toggle, switchToggle } = useToggle();

    const loading = inject("loading");

    const getReports = async () => {
      loading.value = true;
      await store.dispatch("fetchReports");
      loading.value = false;
    };

    const role = computed(() => store.getters.getCurrentRole);
    const isCanEdit = item =>
      role.value === "expert" &&
      (!["REPORT_PUBLISHED", "REPORT_CREATED"].includes(item.status) ||
        item.mainExpertId === store.getters.getAuthData.id);

    const isEditMode = inject("isEditMode");
    const selectReport = async (report, editMode) => {
      loading.value = true;
      if (report.status === "REPORT_PUBLISHED" || editMode) {
        isEditMode.value = editMode;
        await Promise.all([
          store.dispatch("fetchCurrentReport", {
            sessionId: report.id,
            role: role.value,
          }),
          store.dispatch("fetchReportQuestionnaire", report.id),
          store.dispatch("fetchReportAnswers", report.id),
        ]);
      } else root.$toast.warning("Отчет еще не опубликован");
      loading.value = false;
    };

    const { expertReportHeaders } = useTableHeaders(store);
    const headers = computed(() => expertReportHeaders);
    const content = computed(() => store.getters.getReports.content);

    return {
      toggle,
      switchToggle,
      loading,
      getReports,
      role,
      isCanEdit,
      selectReport,
      customSort,
      headers,
      content,
      ...useSetFilters(headers, content),
    };
  },
  components: {
    DataFilter,
    VideoMenu,
  },
};
</script>

<style lang="scss" scoped>
.preloader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
}
</style>
