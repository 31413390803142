<template>
  <v-menu
    v-model="opened"
    :close-on-content-click="false"
    :nudge-width="300"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        class="mr-2"
        small
        v-bind="attrs"
        v-on="on"
        @click="draftLink = item.videoRecordLink"
      >
        <v-icon color="#767676" size="20px">
          {{
            item.videoRecordLink ? "mdi-video-check" : "mdi-video-plus-outline"
          }}
        </v-icon>
      </v-btn>
    </template>

    <v-card class="pa-3">
      <div class="mb-3">
        <p class="subtitle mb-1">Ссылка на запись симуляции:</p>
        <v-text-field
          hide-details
          outlined
          v-model="draftLink"
          type="text"
          :rules="[val => !!val]"
          label="Вставьте ссылку на запись"
        />
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="opened = false"> Отменить </v-btn>
        <v-btn color="primary" text @click="saveVideoRecordLink">
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import { ref } from "@vue/composition-api";

import { useLinks } from "@/use/helpers";

export default {
  props: ["item"],
  setup(_, { root }) {
    const store = root.$store;

    const { validLink } = useLinks();

    const opened = ref(false);
    const draftLink = ref("");
    const saveVideoRecordLink = () => {
      if (!draftLink.value) return;

      opened.value = false;

      const testedVideo = validLink(draftLink.value);

      store.dispatch("setReportVideoLink", {
        sessionId: _.item.id,
        link: testedVideo,
      });
    };

    return {
      opened,
      draftLink,
      saveVideoRecordLink,
    };
  },
};
</script>
